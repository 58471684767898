import React, { useState } from 'react';
import Layout from '@/components/common/layout';
import Container from '@/components/common/container';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '@/components/seo';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';

const simplePortableText = {
  marks: {
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel} className="text-purply-blue">
          {children}
        </a>
      );
    },
  },
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
};

const Success = () => {
  const [pdfActive, setPdfActive] = useState(true);

  const query = useStaticQuery(graphql`
    {
      hlimage: file(relativePath: { eq: "hallucination-thankyou.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `);

  return (
    <Layout>
      <div className="bg-bluey">
        <Container className="pb-14 pt-6 md:pb-12 md:pt-8">
          <div className="rounded-xl border border-[##E7E7E7] bg-white p-4 lg:rounded-[26px]">
            <div className="flex flex-col gap-4 md:flex-row">
              <div className="lg::max-w-lg items-between flex w-full flex-col justify-between md:w-1/2 xl:pl-6">
                <div className="">
                  <h1 className="mb-5 max-w-xs text-[24px] font-normal leading-tight sm:text-[24px] md:text-[28px] lg:mb-7 lg:max-w-md lg:text-[32px] xl:text-[40px]">
                    Enjoy Our LLM Hallucination Index Report 😎
                  </h1>
                  <p className="mb-4 text-base opacity-70 lg:mb-6 lg:text-[18px]">
                    You're on your way to learning:
                  </p>
                  <ul className="mb-6 list-disc space-y-3 pl-4 text-base opacity-70 lg:space-y-4 lg:text-[18px]">
                    <li>Hallucination rankings by task type</li>
                    <li>Correctness and Context Adherence for each model</li>
                    <li>Evaluation methodology for hallucinations</li>
                  </ul>
                </div>

                <OutboundLink
                  rel="noreferrer noopener"
                  href="https://drive.google.com/u/2/uc?id=1d97xAVYKYVSiGFF39p9q85rIwEOLyPx5&export=download"
                  download={true}
                  className="my-6 hidden max-w-xs cursor-pointer bg-hi-100 px-10 py-3.5 text-center font-medium text-white duration-200 hover:bg-hi-60 md:block lg:py-4"
                >
                  Download Now
                </OutboundLink>
              </div>
              <div className="flex grow flex-col items-center justify-center md:items-end md:justify-end">
                <div
                  className={`relative aspect-[1/1.41] w-full overflow-hidden rounded-[9px] xl:max-w-lg`}
                >
                  <iframe
                    src="https://drive.google.com/file/d/1d97xAVYKYVSiGFF39p9q85rIwEOLyPx5/preview?usp=sharing"
                    className={`${
                      pdfActive ? 'opacity-100' : 'opacity-0'
                    } relative z-10 aspect-[1/1.41] w-full rounded-[9px] bg-hi-2 duration-200`}
                    allow="autoplay"
                  ></iframe>
                  {!pdfActive && (
                    <div className="absolute inset-0 z-10 bg-white">
                      <button
                        className={`relative z-10 block aspect-[1/1.41] w-full rounded-[9px] duration-200 hover:opacity-80`}
                        onClick={() => setPdfActive(true)}
                      >
                        <GatsbyImage
                          image={query.hlimage.childImageSharp.gatsbyImageData}
                          alt="Hallucination Index"
                          className="w-full rounded-[9px]"
                        />
                      </button>
                    </div>
                  )}
                </div>
                <a
                  rel="noreferrer noopener"
                  href="https://drive.google.com/u/2/uc?id=1d97xAVYKYVSiGFF39p9q85rIwEOLyPx5&export=download"
                  download={true}
                  className="my-6 block w-full cursor-pointer bg-hi-100 px-10 py-3.5 text-center font-medium text-white duration-200 hover:bg-hi-60 md:hidden lg:py-4"
                >
                  Download Now
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default Success;

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
    <meta name="googlebot" content="noindex" />
    <meta name="googlebot-news" content="noindex" />
    <SEO title={`Enjoy Our LLM Hallucination Index Report`} />
  </>
);
